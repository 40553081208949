import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { ExportRow } from 'core/remodel/database/exportHandler'
import { AssetType } from 'core/remodel/types/enums'
import { ComparativeNetWorthReport, NetWorthReportDetails } from 'core/remodel/types/reports'
import { defaultPreferences, Preferences } from 'core/remodel/types/user'
import { fetchPreferences, userQuery } from '@/api/AccountService'
import { exportData, exportSummary } from '@/api/CommonService'
import { exportReportHeaderMap, subtypeOptionMap } from '@/constants/assets'
import { cn } from '@/utils/classnames'
import { formatDate } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { Button, Modal, Progress, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import { ExportIcon } from '@/components/icon'

interface DataExporterProps {
  assetType: AssetType | 'Global'
  className?: string
}

export default function DataExporter({ assetType, className }: DataExporterProps) {
  const { t } = useTranslation()
  const assetsData = useRef<ExportRow[]>([])
  const summaryData = useRef<ComparativeNetWorthReport | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [percentage, setPercentage] = useState<number>(NaN)
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))

  const handleExport = async () => {
    setIsOpen(true)
    const parse =
      assetType === 'Global'
        ? (v: string) => t(`collectables:OtherCollectableTypeOptions.${v}`, v)
        : (v: string) => t(`${subtypeOptionMap[assetType]}${v}`, v)
    try {
      if (percentage !== 100) {
        setPercentage(0)
        if (assetType === 'Global') {
          summaryData.current = await exportSummary(database!)
          setPercentage(100)
        } else {
          assetsData.current = await exportData(database!, assetType, setPercentage)
        }
      }
      const printElement =
        assetType === 'Global'
          ? formatSummaryReport(summaryData.current!, preferences, parse)
          : formatAssetsReport(assetsData.current, preferences, parse)
      const printWindow = window.open()
      if (printWindow === null) {
        // window is blocked
        setIsOpen(false)
        return
      }
      printWindow.document.write(printElement)
      printWindow.print()
      setIsOpen(false)
    } catch (e) {
      console.log(e)
      setPercentage(NaN)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setPercentage(NaN)
  }

  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            className={cn('h-9 w-9 text-white', className)}
            variant={'solid'}
            onClick={handleExport}
            disabled={isOpen}
          >
            <ExportIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('Export')}</TooltipContent>
      </Tooltip>

      {isOpen && (
        <Modal className={'max-w-md'} onBackdropClick={handleClose}>
          <Modal.Header>
            <label className={'text-sm font-medium uppercase text-primary'}>{t('ExportTitle')}</label>
          </Modal.Header>
          <Modal.Content>
            {!isNaN(percentage!) ? (
              <div className={'flex flex-col items-center justify-center gap-3 text-sm'}>
                <p>{t('ExportDescription')}</p>
                <Progress value={percentage} />
                <p>{`${percentage} %`}</p>

                {percentage !== 100 && (
                  <fieldset className={'flex w-full justify-end gap-2'}>
                    <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={handleClose}>
                      {t('Cancel')}
                    </Button>
                  </fieldset>
                )}
              </div>
            ) : (
              <div className={'text-sm'}>
                {t('ExportFailed')}

                <fieldset className={'mt-4 flex justify-end gap-2'}>
                  <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={handleClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    className={'group relative min-w-[130px]'}
                    variant={'solid'}
                    size={'md'}
                    onClick={handleExport}
                  >
                    <span className={'group-disabled:opacity-0'}>{t('TryAgain')}</span>
                  </Button>
                </fieldset>
              </div>
            )}
          </Modal.Content>
        </Modal>
      )}
    </>
  )
}

function formatAssetsReport(data: ExportRow[], preferences: Preferences, parse?: (str: string) => string) {
  const parseFunc = parse ?? ((v) => v)
  const csvData = data.map((row) => ({
    ...row,
    createdAt: formatDate(row.createdAt, preferences.dateFormat, preferences.timeZone),
    updatedAt: formatDate(row.updatedAt, preferences.dateFormat, preferences.timeZone),
    purchaseDate: formatDate(row.purchaseDate, preferences.dateFormat, preferences.timeZone),
    notes: row.notes || '-'
  }))

  return `
    <style type="text/css">
      table {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
      }
      td {
        border: 1px solid black;
        border-collapse: collapse;
      }
      th {
        text-align: start;
        background-color: grey;
        border: 1px solid black;
        border-collapse: collapse;
      }
    </style>
    <img src="/images/myassets-logo.svg" width="220" height="30" />
    <br />
    <br />
    <table>
      <tr>
        ${Object.values(exportReportHeaderMap)
          .map((value) => `<th>${value}</th>`)
          .join('\n')}
      </tr>
      ${csvData.map(
        (row) => `<tr>
          ${Object.values(row)
            .map((val, index) => {
              if (index === 4) {
                // SubType
                return `<td>${parseFunc(val as string)}</td>`
              }
              return `<td>${val}</td>`
            })
            .join('\n')}
      </tr>`
      )}
    </table> 
  `.replaceAll(',', '')
}

function formatSummaryReport(
  report: ComparativeNetWorthReport,
  preferences: Preferences,
  parse?: (str: string) => string
) {
  const parseFunc = parse ?? ((v) => v)
  const getRow = (data?: NetWorthReportDetails) => {
    if (!data) return []
    return [
      ...Object.values(data.original).map((amount) => amount.value.toString()),
      ...Object.values(data.current).map((amount) => amount.value.toString()),
      ...Object.values(data.percentageChange).map((percent) => percent + '%')
    ]
  }

  const firstHeader = ['Original', 'Current', '% Change']
  const secondHeader = [
    'PURCHASE PRICE',
    'Liabilities',
    'NET INVESTMENT',
    'ASSETS',
    'Liabilities',
    'NET VALUE',
    'ASSETS',
    'Liabilities',
    'NET'
  ]

  const rows = {
    MyFinances: [] as string[],
    'Cash & Banking': getRow(report.assetType['CashAndBanking']),
    'Traditional Investments': getRow(report.assetType['TraditionalInvestments']),
    'Other Investments': getRow(report.assetType['OtherInvestment']),
    '': [] as string[],
    MyCollectables: [] as string[],
    Art: getRow(report.assetType['Art']),
    Wine: getRow(report.assetType['WineAndSpirits']),
    'Other Collectables': getRow(report.assetType['OtherCollectables']),
    ...Object.fromEntries(
      Object.entries(report.subCategory).map(([key, value]) => [`- ${parseFunc(key)}`, getRow(value)])
    ),
    ' ': [] as string[],
    MyProperties: getRow(report.assetType['Property']),
    '  ': [] as string[],
    MyBelongings: getRow(report.assetType['Belonging']),
    '   ': [] as string[],
    TOTAL: getRow(report.total)
  }

  return `
    <style type="text/css">
      table {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
      }
      td {
        text-align: end;
        border: 1px solid black;
        border-collapse: collapse;
      }
      th {
        background-color: grey;
        border: 1px solid black;
        border-collapse: collapse;
      }
      .align-start {
        text-align: start;
      }
      .blank {
        color: #ffffff;
      }
      .negative {
        color: red;
      }
      .bold {
        font-weight: 700;
      }
    </style>
    <img src="/images/myassets-logo.svg" width="220" height="30" />
    <br />
    <br />
    <table>
      <tr>
        <td class="align-start">Report Generated on ${formatDate(
          new Date(),
          preferences.dateFormat,
          preferences.timeZone
        )}</td>
        ${firstHeader.map((label) => `<th colspan="3">${label}</th>`)}
      </tr>
      <tr>
        <td></td>
        ${secondHeader.map((label) => `<th class="align-start">${label}</th>`)}
      </tr>
      ${Object.entries(rows).map(([key, row]) => {
        const title = `<td class="align-start${key.startsWith('My') || key === 'TOTAL' ? ' bold' : ''}">${key}</td>`
        return row.length > 0
          ? `<tr>${title}${row.map((col, index) => {
              const isLiabilityPercentage = index === 7 && col !== '0%'
              const isNegative = col.startsWith('-')
              const value = isNegative ? `(${col.slice(1)})` : col
              return `<td class="${isNegative !== isLiabilityPercentage && 'negative'} ${
                key === 'TOTAL' && 'bold'
              }">${value}</td>`
            })}</tr>`
          : `<tr>${title}${Array(9).fill(`<td class="blank">0</td>`)}</tr>`
      })}
    </table>
  `.replaceAll(',', '')
}
